<template>
<div>
    <div class="svDiv" v-if="type=='gysfkdsj'">
        <div class="svTitle">供应商风控大数据平台产品解决方案</div>
        <div class ="svDiv1">方案概述：</div>
        <div  class ="svDiv2 textIndex">
            Wise供应商风控大数据平台是基于大数据技术的信用评价平台，建立起科学合理的信用评价体系和动态智能风险决策引擎,
            应用于企业内部供应商风险筛查、信用评价、信用管理、评价结果应用等。为企业内部供应商的选择和管理提供决策依据，规避采购风险，打造优质、稳定的供应商供货团队，
            营造企业诚信氛围。
        </div>
        <div class ="svDiv2 textIndex">
            供应商风控大数据平台，有完善合理的供应商管理制度体系支撑，
            从源头保障供应商质量，有效降低采购风险，全流程管理供应商。
            通过科学的信用评价，对供应商实施分级管理，与优质供应商加强战略合作关系，对风险供应商实施监测预警。
        </div>
        <div class ="svDiv2 textIndex">
            供应商风控大数据平台可接入集中采购平台的交易数据，通过供应商交易数据，进行多角度企业画像。
        </div>
        <div class ="svDiv3">
            <img src="../../static/solution/solution8.png" style="width:100%"/>
        </div>
        <div class ="svDiv3" style="margin-top:50px">
            <img src="../../static/solution/solution9.png" style="width:100%" />
        </div>
    </div>
    <div class="svDiv" v-if="type=='jrfkdsj'">
        <div class="svTitle">金融风控大数据平台产品解决方案</div>
        <div class ="svDiv1">方案概述：</div>
        <div  class ="svDiv2 textIndex">
           Wise金融大数据平台联合了专业信用评价机构-专业信用风险分析方法，建立量化风险分析计算工具。
           工具内嵌宏观、中观分析指标，用户通过输入目标企业相关财务及分析数据，工具能够自动生成信用风险评估得分，以及重点风险点简报。
        </div>
        <div class ="svDiv2 textIndex">
            用户可根据评估结果进行发债企业资质初步筛选；还能够通过系统自带的对比功能，
            监测企业部分指标变化对整体信用风险状态的影响程度，明确企业优势和劣势。
        </div>
        <div class ="svDiv2 textIndex">
            基于企业风险分析图谱的智能化风险分析引擎，覆盖从标的企业风险评估、对标企业筛选、金融资产风险定价、投后风险监控管理等金融风控全流程，
            并具备准实时监控系统和预警功能，实现对投前、投中、投后全风控流程业务闭环的技术支撑。
        </div>
        <div class ="svDiv2">
            <ol>
                <li>风险评估
                    <div>从盈利性、成长性、稳健性等角度，对企业的经营风险、财务风险、外部风险等方面进行风险评估，计算风险数值。</div>
                </li>
                <li>风险对标
                    <div>将基础指标数据、中间指标数据、风险评估得分等进行统计分析、排名，精准定位企业在行业中的位置，便于信息使用者判断其风险水平，筛选投资标的公司。
                    </div>
                </li>
                <li>风险定价
                    <div>结合企业风险评估结果、各类融资产品融资成本数据等，形成客观融资利率建议，
                        为资金方合理定价、融资企业控制融资成本提供科学依据。
                    </div>
                </li>
                <li>及时预警
                    <div>基于数据库和全流程数字化评估过程，
                        对定期、不定期数据更新引起的风险评估数据变动进行及时响应，
                        实现数据监测-风险预警一体化。
                    </div>
                </li>
            </ol>
        </div>
        <div class ="svDiv3">
            <img src="../../static/solution/solution7.jpg" />
        </div>
    </div>  
    <div class="svDiv" v-if="type=='jcssjc'">
        <div class="svTitle">基础设施监测产品解决方案</div>
        <div class ="svDiv1">方案概述：</div>
        <div  class ="svDiv2 textIndex">
           无论是大型桥梁，还是城市立体交叉桥梁都是道路交通的重要节点，对交通运输具有重大影响。而桥梁结构的安全状况则是公众十分关注的问题，一旦桥梁发生安全事故，会造成严重的经济损失和不良的社会影响。
           因此建立桥梁健康监测系统，能够实时监测桥梁的状态，预测退化，更能及时安排补救措施，或对事故做出快速反应。
        </div>
        <div class ="svDiv2 textIndex">
            Wise无线智能监测解决方案是基于 FlatMesh 无线Mesh 网络平台，
            针对桥梁，塔架，铁路，建筑物的倾斜、偏移、沉降进行长、短期实时监测提供完整的思路和有效的途径方法。 
        </div>
        <div class ="svDiv2">
            <ol>
                <li>运维简便
                   <div>传感器高度集成，无任何外接电缆，安装简易快捷。</div>
                </li>
                <li>精度高
                     <div>0.0001°（0.00175mm/m）的分辨率,亚毫米的监测精度。</div>
                </li>
                <li>高性能
                    <div>专有算法保证，提供了高度灵活的部署配置和无线网络诊断。</div>
                </li>
                <li>低功耗
                    <div>内置锂电池，长达15年的工作时长。</div>
                </li>
            </ol>
        </div>
        <div class ="svDiv3">
            <img src="../../static/solution/solution6.jpg" />
        </div>
    </div>  
    <div class="svDiv" v-if="type=='spjgfx'">
        <div class="svTitle">视频结构分析产品解决方案</div>
        <div class ="svDiv1">方案概述：</div>
        <div  class ="svDiv2 textIndex">
          视频结构化分析在很多的应用场景下被大量使用，Wise物联网开发平台很擅长对接非结构化的异构系统，并且整合AI视频系统，提取、学习、分析，
          已经在医院场景的视频分析、楼宇社区场景的视频分析、电梯场景的视频分析、工地场景的视频分析、机场场景的视频分析、工厂场景的视频分析等场景中，成熟的被使用。
        </div>
        <div class ="svDiv3">
            <img src="../../static/solution/solution4.jpg" />
        </div>
    </div> 
    <div class="svDiv" v-if="type=='cwrpa'">
        <div class="svTitle">财务RPA服务解决方案</div>
        <div class ="svDiv1">方案概述：</div>
        <div  class ="svDiv2 textIndex">
           RPA 是机器人流程自动化的简称。RPA 通过模拟人工手动操作键鼠，自动处理规则清晰、批量化的高频业务。
           它适用于企业内具有明确业务规则、结构化输入和输出的操作流程，例如读取邮件、对账汇总、检查文件、生成文件和报告等枯燥、重复、标准化的工作，
           都可以让 RPA 机器人代为完成。RPA 能够轻松集成在任何系统上，跨系统处理数据。不仅简化了操作流程，提高了数据处理效率和准确度，还可有效避免人为失误，
           规避业务流程中数据被人为篡改的风险。通过 RPA 可以减轻员工的工作量，将员工从枯燥、重复的数据搬运中释放出来，
           让他们专注于更具创造性的工作，成长为复合型人才，更好地利用数据，为企业创造更多价值。
        </div>
        <div class ="svDiv2">
             <ol>
                <li>业务体验差，报销时间长
                    <div>财务人员审核报销吃力，出差集中时，报销速度慢，难以按照科目管控费用。</div>
                </li>
                <li>业财缺集成，系统难共享
                    <div>财务系统和业务系统未集成，数据不同步，需手工操作财务会计与管理报表，
                        多部门多系统协作困难，人工对账和录入，费时费力。
                    </div>
                </li>
                <li>自动化程度低，组织转型困难
                    <div>业务流程标准化、流程化、自动化程度较低，发票核对、纳税申报等事务耗费财务人员大量时间，
                        财务人员束缚于基础核算工作，无法转型中高端会计人员。
                    </div>
                </li>
                <li>报表不准确，分析预测难
                    <div>管理口径不统一，数据不及时，影响会计报表的准确性，
                        无法高效精细核算，经营数据分析滞后，影响时效性。
                    </div>
                </li>
            </ol>
        </div>
        <div class ="svDiv3">
            <img src="../../static/solution/solution3.jpg" />
        </div>
    </div>
    <div class="svDiv" v-if="type=='zzyrpa'">
        <div class="svTitle">制造业RPA服务解决方案</div>
        <div class ="svDiv1">方案概述：</div>
        <div  class ="svDiv2 textIndex">
           RPA能够模拟人类用户的操作方式，在企业的计算机上跨平台、不间断地执行基于规则的各种AI工作流程，
           同时保持稳定的速度和准确性。RPA以外挂的形式存在，对企业现有系统影响小，
           可以与现有的遗留系统无缝集成，无需昂贵且耗时的软件开发。另外，其还具备实施周期短、投资回报高、不需要编码等特点。
        </div>
        <div class ="svDiv2">
             <ol>
                <li>物料清单自动化生成
                    <div>物料清单（BOM）是制造业中至关重要的数据文件，其包含了构建产品所需的原材料、组件、子组件和其他材料的详细清单，是计算机识别物料的基础依据
            。该文件会向企业内的所有员工提供详细信息，说明所要购买的材料内容、数量、方式、地点以及其他详细说明（如何组装和打包产品）。
            即使是单一的遗漏或小小的失误，也可能导致材料计划、物料需求的错误，产品成本核算不准确，装运延迟等问题的出现。使用RPA可以自动化创建和更新BOM。
            RPA机器人能够完全复制人类员工在生成BOM中所执行的步骤，利用屏幕抓取技术，更快地创建和跟踪变更，帮助企业避免代价高昂的人为错误，实现BOM流程的自动化。
                    </div>
                </li>
                <li>工厂记录管理及报告
                    <div>
                        对于制造企业而言，需要记录和管理大量数据，这其中大部分便来自工厂。特别是一些大型制造商，通常在世界不同地区拥有多家工厂，数据记录及分析的任务量巨大。
            在特定系统中，数据的操控将会变得更加困难。RPA机器人可以帮助制造商更轻松、有效地记录和分析相关信息。其可以自动执行数据的提取与搬运，
            监控、访问和更新Web连接系统中的任何更改，并向业务的其他部分提供报告，提高整体业务效率。另外，
            RPA还能提供揭示企业潜在瓶颈的业务模式和内部工作信息的报告、实时仪表板等。
                    </div>
                </li>
                <li>ERP、MES系统整合
                    <div>
                        根据不同的需要，工厂会部署多项ERP子系统，包括财务管理、生产控制管理、物流管理、采购管理、分销管理、库存控制、人力资源管理等等多个子系统。
            员工经常需要从一个系统中跳到另一个系统中，以实现数据的交换。另外，一些缺乏现代集成界面的运营系统，在实际的操作中，灵活性及透明性往往不足。
            RPA可以整合ERP、MES等系统，代替人工实现各系统间的交互。RPA机器人能自动生成报告（如库存、应付账款和应收账款、定价和其他报告），
            并通过电子邮件自动发送或将其上载到共享点文件夹中。
            其还可以推动MES等运营管理平台与其他管理系统之间的交互操作，有效增加运营的灵活性和透明度。
                    </div>
                </li>
                <li>可以集成SCADA和PLC系统
                    <div>
                        真正的实现数字化工厂，需要一个复杂、高投入、长周期的过程。如何在没有完全搭建IIoT的工厂中，实现数据的采集和分析，打通OT和IT的壁垒，
            RPA给我们提供了另一套思路。RPA以“外挂”方式，在不侵入工厂原有IT或OT系统的情况下，模拟人工操作，采集、汇聚、分析数据，
            对工厂设备的预测性维护、设备能耗分析以及设备资产管理起到重要作用。
                    </div>
                </li>
            </ol>
        </div>
        <div class ="svDiv3">
            <img src="../../static/solution/solution5.jpg" style="width:100%"/>
        </div>
    </div>  
    <div class="svDiv" v-if="type=='bimsj'">
        <div class="svTitle">BIM数据可视化和数字孪生服务解决方案</div>
        <div class ="svDiv1">方案概述：</div>
        <div  class ="svDiv2 textIndex">
           快速洞悉几何关系；BIM数据轻量化导入；制定逼真行为来实现逼真互动可视化展示；方便审查；全平台发布、部署；借助VR&AR审查具备“真实尺寸”和“真实互动”的体验。
        </div>
        <div class ="svDiv2">
             <ol>
                <li>给到业主
                    <div>数字沙盘、运维平台、AR/VR巡检、数字孪生全场景还原</div>
                </li>
                <li>给到设计院
                    <div>
                        Reflect实时设计评审汇报、图纸AR应用
                    </div>
                </li>
                <li>给到施工企业
                    <div>
                        4D/5D仿真模拟、模型和现场比对（AR）、VR施工培训、施工工艺和工法库定制、智慧工地平台建设
                    </div>
                </li>
                <li>给到政府
                    <div>
                        城市数据可视化、数字沙盘全景展示、智慧交通/水务/安防/城管…
                    </div>
                </li>
            </ol>
        </div>
        <div class ="svDiv3">
            <img src="../../static/solution/solution1.jpg" />
        </div>
    </div>  
    </div>
</template>
<script>
export default {
    name:"solutionView",
    data(){
        return{
            type:""
        }
    },
    methods:{
       init(){
          this.type=this.$route.params.type 
       }
    },
    created(){
        this.init()
    },
    watch:{
        $route(){
            this.init()
        }
    }
}

</script>
<style>
.svDiv{
    padding:60px 0;
}
.svDiv .svTitle{
    height: 18px;
    font-size: 18px;
    color: #404756;
    line-height: 18px;
}
.svDiv .svDiv1{
    font-size: 16px;
    color: #404756;
    line-height: 16px;
    margin-top:15px;
}
.svDiv .svDiv2{
    font-size: 14px;
    color: #848484;
    line-height: 26px;
        margin-top: 20px;
}
.svDiv .textIndex{
    text-indent: 2em;
}
.svDiv .svDiv3{
    margin-top:30px;
    text-align:center;
}
</style>